.navbar {
    height: rem(700px);
    width: rem(300px);
    padding: var(--mantine-spacing-md);
    display: flex;
    flex-direction: column;
    border-right: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.navbarMain {
    flex: 1;
}

.header {
    padding-bottom: var(--mantine-spacing-md);
    margin-bottom: calc(var(--mantine-spacing-md) * 1.5);
    border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}
